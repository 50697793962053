<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card-body>
          <b-row>
            <b-col cols="3">
              <b-form-group label="From Date" label-for="From Date">
                <b-form-datepicker
                  id="from-date"
                  label="label"
                  v-model="startDate"
                  class="mb-1"
                  placeholder="Start Date"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="To Date" label-for="To Date">
                <b-form-datepicker
                  id="to-date"
                  label="label"
                  v-model="endDate"
                  class="mb-1"
                  placeholder="End Date"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group label="Driver" label-for="Driver">

              <v-select
                v-model="driver"
                label="label"
                :options="option"
                @input="onChange()"
                placeholder="Select Driver"
              />
              </b-form-group>
            </b-col>

            <b-col md="3">
                <b-button variant="primary" @click="filterAllData"
                  ><feather-icon icon="filterIcons" /> Filter</b-button
                >

                <b-button variant="danger" @click="resetAllData"
                  ><feather-icon icon="filterIcons" /> Reset</b-button
                >
            </b-col>
          </b-row>

          <div></div>
        </b-card-body>
        <b-card>
          <b-card-body class="p-0">
            <h3>Open Requests</h3>
            <b-table
              striped
              hover
              responsive
              class="position-relative open_request"
              :per-page="perPage"
              :current-page="currentPage"
              :items="history_list"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(action)="data">
                <b-button
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  title="Update Status"
                  @click="onEdit(data.item)"
                  style="
                    position: relative;
                    padding: 8px;
                    width: 31px;
                    margin-top: 0px !important;
                    left: -9px;
                  "
                  v-b-modal.modal-center-update-new
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                    title="Edit Defect"
                  />
                </b-button>

                <b-button
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  title="Edit Defect"
                  @click="onEdit(data.item)"
                  style="
                    position: relative;
                    padding: 8px;
                    width: 31px;
                    margin-top: 0px !important;
                  "
                  :to="{
                    name: 'apps-reports-timeoff-open-requests-edit',
                    params: { id: data.item.id },
                  }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" title="Edit" />
                </b-button>
              </template>
            </b-table>
          </b-card-body>

          <b-modal
            size="lg"
            id="modal-center-new"
            centered
            title="Add Notes"
            ok-only
            ok-title="Cancel"
            ref="modal-center-new"
          >
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Notes"
                  label-for="h-remarks"
                  label-cols-md="4"
                >
                  <b-form-textarea
                    v-model="remarks"
                    id="h-title"
                    placeholder="Enter Notes"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Status"
                  v-slot="{ ariaDescribedby }"
                  style="display: flex"
                >
                  <b-form-radio
                    v-model="remarkStatus"
                    class="custom-control-success"
                    :aria-describedby="ariaDescribedby"
                    name="radio-inline"
                    value="1"
                    >Approve</b-form-radio
                  >
                  <b-form-radio
                    v-model="remarkStatus"
                    class="custom-control-danger"
                    :aria-describedby="ariaDescribedby"
                    name="radio-inline"
                    value="2"
                    >Deny</b-form-radio
                  >
                </b-form-group>

                <!-- Approve
                    <label class="switch" title="Approve">

                      :checked="data.item.status" :value="data.item.id"
                      
                      <input type="checkbox" class="success" @click="toggleCheckbox($event,1)"  />
                      <div class="slider round"></div>
                    </label>
                    -->
              </b-col>
            </b-row>
            <b-row>
              <b-col md="8">
                <b-form-group
                  label=""
                  label-for="h-status-name"
                  label-cols-md="4"
                >
                  <b-button
                    class="btn-sm"
                    variant="primary"
                    @click="toggleCheckboxSubmit"
                  >
                    Submit
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
        
        <script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BForm,
  BTable,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BFormCheckboxGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormTimepicker,
  BFormDatepicker,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import { required, min, confirmed } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { serverUri } from "@/config";
import router from "@/router";
import { getUserData } from "@/auth/utils";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import axios from "axios";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BFormRadio,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormCheckbox,
    BFormTextarea,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BInputGroup,
    BInputGroupAppend,
    BFormTimepicker,
    BFormDatepicker,
    flatPickr,
    DateRangePicker,
    vSelect,
    BDropdownItem,
    BDropdown,
  },
  data() {
    return {
      requestId: "",
      remarks: "",
      remarkStatus: "",
      checkbox: false,
      dateRange: "",
      date: "",
      out_date: "",
      userId: getUserData().id,
      clockId: router.currentRoute.params.id,
      clock_in: "",
      clock_out: "",
      companyId: getUserData().company_id,
      dateRange: {
        startDate: new Date().toDateString(),
        endDate: new Date().toDateString(),
      },
      startDate: "",
      endDate: "",
      perPage: 25,
      selectedDriver: "",
      driver: "",
      pageOptions: [5, 10, 15, 25],
      option: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: " ",
      history_list: [],
      filterOn: [],
      fields: [
        {
          key: "driver_name",
          label: "Driver",
          sortable: false,
          formatter: (value) => {
            return value.toUpperCase();
          },
        },
        {
          key: "from_date",
          label: "From Date",
          sortable: false,
          formatter: (value) => {
            return moment(value).format("MM/DD/YYYY");
          },
        },
        {
          key: "to_date",
          label: "To Date",
          sortable: false,
          formatter: (value) => {
            return moment(value).format("MM/DD/YYYY");
          },
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
          formatter: (value) => {
            return value == 1 ? "Approved" : "Pending";
          },
        },
        {
          key: "action",
        },
      ],
    };
  },
  mounted() {
    this.getRequestOffHistory();
  },
  computed: {},
  props: {},
  methods: {
    onEdit(details) {
      this.requestId = details.id;
      this.$refs["modal-center-new"].show();
    },
    toggleCheckboxSubmit() {
      // var IsChecked = event.target.checked;
      // var main_id = event.target.value
      // if(IsChecked == true)
      // {

      if (this.remarks == "") {
        this.showToast("warning", "Failure", "Please add notes");
        return false;
      }

      if (this.remarkStatus == "") {
        this.showToast("warning", "Failure", "Please choose status");
        return false;
      }

      this.$http
        .post(`${serverUri}/update-time-off-request`, {
          company_id: this.companyId,
          main_id: this.requestId,
          notes: this.remarks,
          status: this.remarkStatus,
        })
        .then((response) => {
          this.data = response.data;
          if (response.data.status == true) {
            const TypeValue = this.remarkStatus == 1 ? "Approved" : "Denied";
            this.$swal({
              icon: "success",
              title: "Request Off Time",
              text:
                "This request time has been " + TypeValue + " Successfully.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then((result) => {
              this.$router.push({
                name: "apps-reports-timeoff-close-requests",
              });
            });
            // this.getRequestOffHistory();
          }
        });
      // }
    },

    filterAllData() {
      this.showUpdate = true;
      this.getRequestOffHistory();
    },

    onChange() {
      this.selectedDriver = this.driver?.title;
    },

    resetAllData() {
      this.showUpdate = true;
      (this.startDate = ""),
        (this.endDate = ""),
        (this.driver = { title: "", label: "" }),
        (this.selectedDriver = ""),
        this.getRequestOffHistory();
    },
    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },

    onUpdate() {},

    getRequestOffHistory() {
      this.$http
        .post(`${serverUri}/request-off-history`, {
          driver_id: this.selectedDriver == undefined ? '' : this.selectedDriver,
          company_id: this.companyId,
          status: 0,
          start_date: this.startDate,
          end_date: this.endDate,
        })
        .then((response) => {
          this.data = response.data;
          if (response.data.status == true) {
            this.history_list = response.data.data;
          } else {
            this.history_list = [];
          }
        });
    },

    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  created() {
    axios
      .get(`${serverUri}/drivers/alldriversdata/${this.companyId}`)
      .then((res) => {
        res.data.map((item) => {
          this.option.push({ title: item.id, label: item.driver_name });
        });
      });
  },
};
</script>
        
        <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.edit-header {
  display: flex;
  align-items: center;
}
</style><style>
.permission {
  margin: 10px 0;
}

.permission div {
  margin: 2px 0;
}
button.btn.btn-danger {
  margin-left: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 33px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #27f223;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* .dropdown {
  top: -20px;
} */
.open_request tr td:nth-child(5) {
  display: inline-flex;
}
</style>
        